import React, { HTMLAttributes } from 'react';
import Imgix from 'react-imgix';
import clsx from 'clsx';
import styled from '@emotion/styled';
import { Property } from 'csstype';

import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';

export interface OptionalImageProps {
  /**
   how much width should the image take on the screen
   the srcset that will be loaded will be the one closest to this value
   */
  sizes?: string;
  /**
   set width explicitly if it is known beforehand
   */
  width?: number;
  /**
   set height explicitly if it is known beforehand
   */
  height?: number;
  /**
   set the object-position for the image
   */
  fit?: Property.ObjectFit;
}

export interface ImgProps extends HTMLAttributes<HTMLImageElement> {
  src: string;
  alt?: string;
  className?: string;
  /**
   how much width should the image take on the screen
   the srcset that will be loaded will be the one closest to this value
   */
  sizes?: string;
  /**
   set width explicitly if it is known beforehand
   */
  width?: number;
  /**
   set height explicitly if it is known beforehand
   */
  height?: number;
  /**
   set the object-position for the image
   */
  fit?: Property.ObjectFit;
}

const ImgRoot = styled(Imgix)<ImgProps>`
  object-fit: ${({ fit }) => fit};
`;

/**
 <h3>A component using react-imgix to render an image with mutliple srcset</h3>
 **/
const Img = ({
  src,
  alt,
  className,
  sizes = '100vw',
  width,
  height,
  fit = 'cover',
  ...rest
}: ImgProps): JSX.Element => {
  return (
    <ImgRoot
      fit={fit}
      className={clsx('lazyload', className)}
      src={src}
      // @ts-ignore
      alt={alt}
      sizes={sizes}
      width={width}
      height={height}
      attributeConfig={{
        src: 'data-src',
        srcSet: 'data-srcset',
        sizes: 'data-sizes',
      }}
      htmlAttributes={{ ...rest }}
    />
  );
};

export default Img;
